export default {
    props: {
        rules: {
            type: String,
            default: "",
        },
        value: {
            type: [String, Object, Array, Number, FileList, Boolean],
        },
        name: {
            type: String,
            required: true,
        },
        validate: {
            type: Boolean,
            default: true,
        },
        options: {
            type: Array,
            default: null,
        },
        customClass: {
            type: [String, Object, Array],
            default() {
                return [];
            },
        },
        emitWithName: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: "eager",
        },
        placeholder: {
            type: String,
        },
        emitValueFromObject: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        onInput(value) {
            if (
                value != null &&
                typeof value === "object" &&
                value.value &&
                this.emitValueFromObject
            ) {
                value = value.value;
            }
            this.$emit("input", value);
            this.$emit("input-with-name", { value, name: this.name });
        },
    },
};
