<template>
  <Modal
    modalId="share-rent-log-modal"
    :no-close-on-backdrop="false"
    :hide-header="true"
    :hide-footer="true"
  >
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="fs-30px fw-700">
            {{ $t("Share Rent Log") }}
          </div>
          <p class="text-gray mt-2">
            {{ $t("You are about to share the Rent Log with all candidates.") }}
          </p>
        </div>
      </div>
      <p
        class="text-danger"
        v-if="getErrors.share_rent_log && getErrors.share_rent_log.length"
      >
        {{ getErrors.share_rent_log }}
      </p>

      <div class="row mt-4">
        <div class="col-md-12">
          <BButton
            :disabled="getLoadings.share_rent_log"
            type="submit"
            block
            variant="primary"
            @click="onSubmit"
          >
            {{ $t("Share Rent Log") }}
            <b-spinner
              v-if="getLoadings.share_rent_log"
              label="Spinning"
              small
              variant="white"
            ></b-spinner>
          </BButton>
        </div>
        <div class="col-md-12 pt-0">
          <button
            class="btn btn-white btn-block"
            type="button"
            @click="$bvModal.hide('share-rent-log-modal')"
          >
            {{ $t("Cancel") }}
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Modal from "../../../components/ui/Modal.vue";

export default {
  name: "ShareRentLogModal",
  props: [],
  components: {
    Modal,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
  },
  methods: {
    ...mapActions("requestsAndAppointment", ["shareRentLog"]),
    async onSubmit() {
      await this.shareRentLog();
      this.$bvModal.hide("share-rent-log-modal");
    },
  },
};
</script>
<style lang="scss">
.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: $black !important;
}
</style>
