<template>
  <div class="container">
    <template v-if="!getLoadings.assigning && listing">
      <div class="d-flex justify-content-between">
        <h2 class="fw-bold heading text-black mb-4" v-if="listing.title">
          {{ "Toewijzen: " + listing.title }}
        </h2>
        <div>
          <BButton
            v-b-modal.cancel-assigning
            variant="white"
            :disabled="getLoadings.cancelAssigning"
          >
            {{ $t("Cancel assignment") }}
          </BButton>
          <!-- <router-link
            class="btn btn-white ml-3"
            :to="`/broker/listings/${listing.id}/agreement`"
          >
            {{ $t("Create agreement") }}
          </router-link> -->
          <b-button
            v-b-modal.share-rent-log-modal
            variant="primary"
            class="ml-3"
            :disabled="getLoadings.share_rent_log"
          >
            {{ $t("Share Rent Log") }}
          </b-button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" v-if="listing.assigned">
          <TenantInfoCard
            v-for="(tenant, key) in listing.assigned.user.tenants"
            :key="key"
            :tenant="tenant"
            @show-details="showDetails"
          />
        </div>
        <div class="col-md-6" v-if="user">
          <CardV2 class="mb-4">
            <div class="row">
              <div class="col-md-12">
                <h5 class="fw-bold">
                  {{ $t("Household") }}
                </h5>
              </div>
              <div class="col-md-6">
                <label for="">
                  {{ $t("Total person in household") }}
                </label>
                <div class="fw-bold">{{ user.totalPersons }} {{ $t("person(s)") }}</div>
              </div>
              <div class="col-md-6">
                <label for="">
                  {{ $t("Gross monthly income") }}
                </label>
                <div class="fw-bold">€{{ user.grossMonthlyIncome }}</div>
              </div>
            </div>
          </CardV2>

          <CardV2 class="mb-5 pb-5">
            <div class="row">
              <div class="col-md-12">
                <h5 class="fw-bold">
                  {{ $t("Preferences") }}
                </h5>
              </div>
              <div class="col-md-12">
                <div class="mb-2 fs-14px">
                  {{ $t("Prefered housing type") }}
                </div>
                <div class="fw-bold mb-4 text-capitalize">
                  {{ user.preferredHousingType.join(",") }}
                </div>
                <div class="mb-2 fs-14px">
                  {{ $t("Household composition") }}
                </div>
                <div class="fw-bold mb-4 text-capitalize">
                  {{ user.householdComposition }}
                </div>
                <div class="mb-2 fs-14px">
                  {{ $t("Prefered municipality") }}
                </div>
                <div class="fw-bold text-capitalize">
                  {{ user.preferredMunicipality.join(",") }}
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-2 fs-14px">
                  {{ $t("Min. living space") }}
                </div>
                <div class="fw-bold">{{ user.minLivingSpace }}</div>
              </div>
              <div class="col-md-6">
                <div class="mb-2 fs-14px">
                  {{ $t("Max. living space") }}
                </div>
                <div class="fw-bold">{{ user.maxLivingSpace }}</div>
              </div>
              <div class="col-md-6">
                <div class="mb-2 fs-14px">
                  {{ $t("Min. rooms") }}
                </div>
                <div class="fw-bold">{{ user.minRooms }}</div>
              </div>
              <div class="col-md-6">
                <div class="mb-2 fs-14px">
                  {{ $t("Max. rooms") }}
                </div>
                <div class="fw-bold">{{ user.maxRooms }}</div>
              </div>
              <div class="col-md-6">
                <div class="mb-2 fs-14px">
                  {{ $t("Pets") }}
                </div>
                <div class="fw-bold">
                  {{ user.pets ? $t("I have pets") : $t("I don't have pets") }}
                </div>
              </div>
            </div>
          </CardV2>
        </div>
      </div>

      <TenantDetailModal :tenant="tenant" @close="tenant = null" />
      <CancelAssigningModal />
      <ShareRentLogModal />
      <Modal modalId="confirm-delete1" :hide-header="true" :hide-footer="true">
        <h1>
          {{ $t("Cancel assignment") }}
        </h1>
        <p>
          {{ $t("Are you sure you want to cancel this assignment") }}
        </p>
        <div class="d-flex justify-content-between">
          <button class="btn btn-white btn-sm" @click="$bvModal.hide('confirm-delete')">
            {{ $t("Cancel") }}
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click="cancelAssignment"
            :disabled="getLoadings.cancelAssigning"
          >
            <b-spinner
              v-if="getLoadings.cancelAssigning"
              small
              label="Spinning"
              variant="white"
            ></b-spinner>
            {{ $t("Cancel assignment") }}
          </button>
        </div>
      </Modal>
    </template>
    <Spinner v-else />
  </div>
</template>

<script>
import Spinner from "@/components/ui/Spinner.vue";
import { mapGetters, mapActions } from "vuex";
import TenantInfoCard from "@/components/Tenant/InfoCard";
import TenantDetailModal from "@/components/Tenant/DetailModal";
import CardV2 from "../../../components/general/CardV2.vue";
import Modal from "../../../components/ui/Modal.vue";
import CancelAssigningModal from "./CancelAssigningModal.vue";
import ShareRentLogModal from "./ShareRentLogModal.vue";

export default {
  name: "ListingAssigned",
  components: {
    Spinner,
    TenantInfoCard,
    TenantDetailModal,
    CardV2,
    Modal,
    CancelAssigningModal,
    ShareRentLogModal,
  },
  data() {
    return {
      selectedRowId: null,
      tenant: null,
    };
  },
  computed: {
    ...mapGetters("requestsAndAppointment", ["assignings", "listing", "user"]),
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    listingId() {
      return this.$route.params.listingId;
    },
  },
  methods: {
    ...mapActions("requestsAndAppointment", ["cancelAssigning", "getAssignings"]),
    showDetails(tenant) {
      this.tenant = tenant;
      // this.$bvModal.show("tenant-modal");
      this.$router.push({
        name: "ListingAssignedTenantDetail",
        params: {
          listingId: this.listingId,
          tenant_id: tenant.id,
        },
      });
    },
    async cancelAssignment() {
      await this.cancelAssigning();
      this.$router.push({
        name: "AssigningTable",
        params: { listingId: this.listing.id },
      });
    },
  },
  created() {
    console.log("assigned created", this.listingId, this.listing?.id);
    if (this.listingId != this.listing?.id || !this.listing?.assigned?.user) {
      this.getAssignings({ listingId: this.listingId });
    }
  },
};
</script>
